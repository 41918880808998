.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: fit-content;
  overflow-x: hidden;
  padding: 2rem;
}

.projects--header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem;
}

.projects--header h1 {
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-family: var(--primaryFont);
}

.projects--body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects--bodyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.5rem;
  width: 100%;
}

.projects--viewAll {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.projects--viewAll a button {
  outline: none;
  border: none;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: inherit;
  border-radius: 45px;
  font-size: 1.05rem;
  font-family: var(--primaryFont);
  font-weight: 500;
  padding-left: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .projects--bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .projects--header h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .projects--header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .projects--viewAll {
    position: relative;
    margin-top: 4rem;
  }
  .projects--viewAll a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
